import axios from 'axios';
import * as t from './../../redux/actionTypes';
import { req, ok, fail } from '../../util/req';
import { getPercentageComplete } from './stageOrder';
import ValidateIsPostcode from './../form/validators/postcode';

export function updateField(value, field) {
  return {
    type: t.UPDATE_FORM_FIELD,
    payload: { field, value },
  };
}

export function updateSelectBoolField(value, field) {
  return {
    type: t.UPDATE_SELECT_BOOL_FIELD,
    payload: { field, value },
  };
}

export function updateSelectField(value, field) {
  return {
    type: t.UPDATE_SELECT_FIELD,
    payload: { field, value },
  };
}

export function updateNumberField(value, field) {
  return {
    type: t.UPDATE_NUMBER_FIELD,
    payload: { field, value },
  };
}

export function toggleBoolField(value, field) {
  return {
    type: t.TOGGLE_BOOL_FIELD,
    payload: { field, value },
  };
}

export function setStage(stageName) {
  return {
    type: t.SET_STAGE,
    payload: { stageName },
  };
}

export function setTimerStage(stageName) {
  return {
    type: t.SET_TIMER_STAGE,
    payload: { stageName },
  };
}

export function nextStage(event) {
  if (event) event.preventDefault();
  return {
    type: t.NEXT_STAGE,
  };
}

export function prevStage(event) {
  event.preventDefault();
  return {
    type: t.PREV_STAGE,
  };
}

export function stageReady() {
  return {
    type: t.STAGE_READY,
  };
}

export function submitForm() {
  return {
    type: t.SUBMIT_FORM,
  };
}

export function loadSavedForm() {
  return {
    type: t.LOAD_SAVED,
  };
}

export function percentageCompleteSelector(activeStage, hidden) {
  return {
    type: t.PERCENTAGE_COMPLETE,
    payload: getPercentageComplete(activeStage, hidden),
  };
}

export function setDOBPart(partName, value) {
  return {
    type: t.SET_DOB_PART,
    payload: {
      partName,
      value,
    },
  };
}

export function fetchAddresses(postcode, prev = false) {
  return dispatch => {
    // only send to scout if this is a valid postcode

    if (!ValidateIsPostcode(postcode)) {
      return false;
    }

    dispatch(
      req(t.REQUEST_ADDRESSES, {
        prev,
      })
    );
    axios
      .get(`${process.env.GET_ADDRESS_URL}?pc=${postcode}`)
      .then(response => {
        dispatch(
          ok(t.REQUEST_ADDRESSES, {
            data: response.data,
            prev,
          })
        );
      })
      .catch(error => {
        console.log(error);
        dispatch(fail(t.REQUEST_ADDRESSES, error, prev));
      });
  };
}

export function selectAddress(index, prev = false) {
  return {
    type: t.SELECT_ADDRESS,
    payload: {
      index,
      prev,
    },
  };
}
