import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const RepresentativeApr = () => (
  <StaticQuery
    query={graphql`
      query RepresentativeAprQuery {
        contentfulRepresentativeApr {
          id
          repApr {
            id
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => {
      const contentMarkdown =
        data.contentfulRepresentativeApr.repApr.childMarkdownRemark.html;

      return (
        <div
          className="repApr"
          dangerouslySetInnerHTML={{
            __html: contentMarkdown,
          }}
        />
      );
    }}
  />
);

export default RepresentativeApr;
