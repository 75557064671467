import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import { Container, Row, Col } from 'reactstrap';
import HeroBanner from '../components/banner';
import ValueExplainer from '../components/valueExplainer';
import MoneyWarn from '../components/moneyWarning';

const standardLayout = ({ data }) => {
  const pageContent = data.contentfulPage;
  const contentMarkDown =
    pageContent.mainContentSection.childMarkdownRemark.html;

  return (
    <Layout>
      <Helmet
        title={pageContent.title}
        meta={[
          { name: 'description', content: pageContent.metaDescription },
          {
            name: 'google-site-verification',
            content: 'r6dHyChKiYN2x15uUQr1gX3rxhygx8woEQRAJahBgIE',
          },
        ]}
      >
        <html lang="en" />
      </Helmet>

      {pageContent.hasAHeroBanner && <HeroBanner content={pageContent} />}

      {pageContent.hasAValueExplainer && <ValueExplainer />}

      <div className="landingPageContent">
        <Container>
          <Row>
            <Col>
              {!pageContent.hasAHeroBanner && (
                <h1>{pageContent.mainHeadline}</h1>
              )}
              <section
                id="mainContent"
                dangerouslySetInnerHTML={{
                  __html: contentMarkDown,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <MoneyWarn />
    </Layout>
  );
};

export default standardLayout;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      slug
      metaDescription
      title
      mainHeadline
      subHeadline
      hasAHeroBanner
      hasAValueExplainer
      defaultLoanAmount
      maxLoanAmount
      minLoanAmount
      mainContentSection {
        id
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulPage(filter: { node_locale: { regex: "/en-US/" } }) {
      edges {
        node {
          slug
        }
      }
    }
  }
`;
