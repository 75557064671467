import React from 'react';
import { Container, Jumbotron, Row, Col } from 'reactstrap';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MediaQuery from 'react-responsive';
import { updateField, setStage, setTimerStage } from './form/formActions';
import CoreValueProposition from './coreValueProposition';
import RepresentativeApr from './representativeApr';
import LoanCalculator from './loanCalculator';
import * as f from '../constants/fieldNames';
import Img from 'gatsby-image';

const imageContainerStyles = {
  position: `absolute`,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  opacity: 0.4,
};

let defaultLaSet = false;

const mapStateToProps = ({ data }) => {
  return { data };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateField,
      setStage,
      setTimerStage,
    },
    dispatch
  );
};

const HeroBanner = ({
  content,
  updateField,
  data,
  setStage,
  setTimerStage,
}) => {
  const { defaultLoanAmount, maxLoanAmount, minLoanAmount } = content;

  if (defaultLoanAmount && !defaultLaSet) {
    updateField(defaultLoanAmount, f.LOAN_AMOUNT);
    defaultLaSet = true;
  }

  return (
    <StaticQuery
      query={graphql`
        query HeroBannerQuery {
          file(relativePath: { eq: "banner.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={contentData => {
        const loanCalcProps = {
          data,
          updateField,
          setStage,
          setTimerStage,
          defaultLoanAmount,
          maxLoanAmount,
          minLoanAmount,
        };
        const headlines = (
          <React.Fragment>
            <h1>{content.mainHeadline}</h1>
            <h2>{content.subHeadline}</h2>
          </React.Fragment>
        );
        return (
          <Jumbotron fluid className="heroBanner">
            <Img
              style={imageContainerStyles}
              fluid={contentData.file.childImageSharp.fluid}
            />
            <Container>
              <Row>
                <MediaQuery minWidth={768}>
                  <Col xl="8" lg="7">
                    {headlines}
                    <CoreValueProposition />
                  </Col>
                  <Col xl="4" lg="5">
                    <LoanCalculator {...loanCalcProps} />
                  </Col>
                </MediaQuery>
                <MediaQuery maxWidth={767}>
                  <Col>
                    {headlines}
                    <LoanCalculator {...loanCalcProps} />
                    <CoreValueProposition />
                  </Col>
                </MediaQuery>
              </Row>

              <Row>
                <Col>
                  <RepresentativeApr />
                </Col>
              </Row>
            </Container>
          </Jumbotron>
        );
      }}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeroBanner);
